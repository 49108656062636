import React, { Fragment, useEffect, useState } from "react";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import Box from "@mui/material/Box";
import MobileStepper from "@mui/material/MobileStepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Question from "./components/Questions";
import { Typography, useMediaQuery } from "@mui/material";
import { initializeApp } from "https://www.gstatic.com/firebasejs/10.11.1/firebase-app.js";
import { getAnalytics } from "https://www.gstatic.com/firebasejs/10.11.1/firebase-analytics.js";
import {
  getDatabase,
  set,
  ref,
  push,
} from "https://www.gstatic.com/firebasejs/10.11.1/firebase-database.js";

var steps = [
  {
    title: "Yılın Antrenörü",
    options: [
      {
        title: "Alberto Giuliani",
        img: "assets/alberto_guliani.jpg",
        description:
          "A Milli Erkek Voleybol Takımı Başantrenörü - 2023 FIVB Challenger Cup ve CEV Golden League Şampiyonu",
      },
      {
        title: "Daniele Santarelli",
        img: "assets/daniele_santarelli.jpg",
        description:
          "A Milli Kadın Voleybol Takımı Başantrenörü - 2023 FIVB Voleybol Milletler Ligi, CEV Avrupa Voleybol Şampiyonası Şampiyonu",
      },
      {
        title: "Okan Buruk",
        img: "assets/okan_buruk.jpg",
        description:
          "Galatasaray Teknik Direktörü - 2022 - 2023 Sezonu Spor Toto Süper Lig Şampiyonu",
      },
      {
        title: "Vincenzo Montella",
        img: "assets/vincenzo_montella.jpg",
        description:
          "A Milli Futbol Takımı'na EURO 2024 katılım hakkını kazandıran Teknik Direktör",
      },
      {
        title: "Yılmaz Göktekin",
        img: "assets/yilmaz_goktekin.jpg",
        description:
          "Erkek Artistik Cimnastik Milli Takımı Antrenörü - Türkiye cimnastik tarihinde ülkemize takım halinde alınan ilk Olimpiyat kotası ve Paris 2024 takım kotası kazandıran Teknik Direktör",
      },
      {
        title: "Yusuf Göktuğ Ergin",
        img: "assets/yusuf_gotug_ergin.jpg",
        description:
          "Okçuluk Milli Takımı Antrenörü - Dünya Şampiyonu Mete Gazoz'un Antrenörü",
      },
    ],
  },
  {
    title: "Yılın Çıkış Yapan Sporcusu",
    options: [
      {
        title: "Arda Güler",
        img: "assets/arda_guler.jpg",
        description: "Fenerbahçe'den Real Madrid'e transfer olan futbolcu",
      },
      {
        title: "Aysu Türkoğlu",
        img: "assets/aysu_turkoglu.jpg",
        description:
          "Kuzey Kanalı'nı yüzerek geçen ilk ve en genç Türk kadın sporcu",
      },
      {
        title: "Cansu Bektaş",
        img: "assets/cansu_bektas.jpg",
        description:
          "2023 Avrupa Halter Şampiyonası - 3 Altın Madalya sahibi sporcu",
      },
      {
        title: "Gizem Örge",
        img: "assets/gizem_orge.jpg",
        description:
          '2023 CEV Avrupa Voleybol Şampiyonası ve FIVB Voleybol Milletler Ligi "En iyi Libero" unvanı sahibi, iki organizasyonda da Rüya Takım\'a seçilen voleybolcu',
      },
      {
        title: "Kenan Yıldız",
        img: "assets/kenan_yildiz.jpg",
        description:
          "Juventus'ta başarısıyla öne çıkan, A Milli Futbol Takımı sporcusu",
      },
      {
        title: "Kuzey Tunçelli",
        img: "assets/kuzey_tuncelli.jpg",
        description:
          "2023 Avrupa Gençler Yüzme Şampiyonası, EYOF ve Dünya Gençler Yüzme Şampiyonası - Altın Madalya sahibi sporcu",
      },
    ],
  },
  {
    title: "Gillette Venus Yılın Kadın Sporcusu",
    options: [
      {
        title: "Aysu Türkoğlu",
        img: "assets/aysu_turkoglu.jpg",
        description:
          "Kuzey Kanalı'nı yüzerek geçen ilk ve en genç Türk kadın sporcu",
      },
      {
        title: "Buse Naz Çakıroğlu",
        img: "assets/buse_naz_cakiroglu.jpg",
        description: "2023 Avrupa Oyunları Altın Madalya sahibi boksör",
      },
      {
        title: "Buse Tosun Çavuşoğlu",
        img: "assets/buse_tosun.jpg",
        description: "2023 Dünya Şampiyonası Altın Madalya sahibi güreşçi",
      },
      {
        title: "Melissa Vargas",
        img: "assets/melissa_vargas.jpg",
        description:
          "2023 FIVB Voleybol Milletler Ligi, CEV Avrupa Voleybol Şampiyonası ve 2022 - 2023 Sezonu misli.com Sultanlar Ligi Final Serisi MVP'si voleybolcu",
      },
      {
        title: "Tuğba Danışmaz",
        img: "assets/tugba_danismaz.jpg",
        description:
          "2023 Avrupa Salon Atletizm Şampiyonası Altın Madalya, 2023 Avrupa Oyunları Gümüş Madalya sahibi üç adım atlama sporcusu",
      },
      {
        title: "Zehra Güneş",
        img: "assets/zehra_gunes.jpg",
        description:
          "2023 CEV Avrupa Voleybol Şampiyonası \"En İyi Orta Oyuncu\" unvanı sahibi, CEV Avrupa Voleybol Şampiyonası ve FIVB Voleybol Milletler Ligi'nde Rüya Takım'a seçilen voleybolcu",
      },
    ],
  },
  {
    title: "AXA Sigorta Yılın Takımı",
    options: [
      {
        title: "A Milli Futbol Takımı",
        img: "assets/A_Milli_Futbol.jpg",
        description: "EURO 2024 katılım hakkı kazanan takım",
      },
      {
        title: "A Milli Kadın Voleybol Takımı",
        img: "assets/a_Milli_Voleybol.jpg",
        description:
          "2023 FIVB Voleybol Milletler Ligi, CEV Avrupa Voleybol Şampiyonası Şampiyonu takım",
      },
      {
        title: "Eczacıbaşı Dynavit",
        img: "assets/Eczacibasi_Dynavit.jpg",
        description: "FIVB Dünya Kulüpler Şampiyonası Şampiyonu takım",
      },
      {
        title: "Erkek Artistik Cimnastik Milli Takımı",
        img: "assets/Erkek_Artistik_Cimnastik.jpg",
        description:
          "Türkiye cimnastik tarihinde takım halinde alınan ilk Olimpiyat kotası ve Paris 2024 takım kotası kazanan takım",
      },
      {
        title: "Fenerbahçe Alagöz Kadın Basketbol Takımı",
        img: "assets/fenerbahce.jpg",
        description:
          "EuroLeague Women ve 2022 - 2023 Sezonu ING Kadınlar Basketbol Süper Ligi Şampiyonu",
      },
      {
        title: "Vakıfbank",
        img: "assets/vakifbank_voleybol.jpg",
        description:
          "2023 CEV Şampiyonlar Ligi ve 2023 AXA Sigorta Türkiye Kupası Şampiyonu",
      },
    ],
  },
  {
    title: "Yılın Paralimpik - Engelli Sporcusu/Takımı",
    options: [
      {
        title: "Ayşe Başaran",
        img: "assets/ayse_basaran1.jpg",
        description:
          "2023 Özel Olimpiyatlar Dünya Yaz Oyunları, Atletizm - 2 Altın Madalya sahibi sporcu",
      },
      {
        title: "Golbol Kadın Milli Takımı",
        img: "assets/golbol_kadin_futbol_milli_takimi.jpg",
        description: "2023 Avrupa Şampiyonası - Altın Madalya sahibi takım",
      },
      {
        title: "Kadın Judo Takımı",
        img: "assets/kadin_judo.jpg",
        description: "2023 Avrupa Şampiyonası - Altın Madalya sahibi takım",
      },
      {
        title: "Milli Paralimpik Yüzme Takımı",
        img: "assets/milli_paralimpik_yuzme.jpg",
        description:
          "Berlin 2022 Para Yüzme Dünya Serisi - 27 Madalya sahibi takım",
      },
      {
        title: "Öznur Cüre",
        img: "assets/oznur_cure1.jpg",
        description:
          "2023 Dünya Para Okçuluk Şampiyonası - Altın Madalya sahibi sporcu",
      },
      {
        title: "Para Tekvando Milli Takımı",
        img: "assets/para_tekvando.jpg",
        description:
          "2023 Avrupa Para Tekvando Şampiyonası - 4 Altın, 1 Gümüş ve 2 Bronz Madalya ile şampiyon olan takım",
      },
    ],
  },
  {
    title: "Yılın Sporcusu",
    options: [
      {
        title: "Adem Asil",
        img: "assets/adem_asil.jpg",
        description:
          "2023 Artistik Cimnastik Avrupa Şampiyonası, Genel Tasnif ve Halka Aleti - Altın Madalya sahibi sporcu",
      },
      {
        title: "Melissa Vargas",
        img: "assets/melissa_vargas.jpg",
        description:
          "2023 FIVB Voleybol Milletler Ligi, CEV Avrupa Voleybol Şampiyonası ve 2022 - 2023 Sezonu misli.com Sultanlar Ligi Final Serisi MVP'si voleybolcu",
      },
      {
        title: "Merve Dinçel",
        img: "assets/merve_dincel.jpg",
        description:
          'Dünya Tekvando Federasyonu tarafından verilen "2023 Yılının En İyi Kadın Sporcusu" unvanı, 2023 Dünya Tekvando Şampiyonası Altın Madalya sahibi tekvandocu',
      },
      {
        title: "Mete Gazoz",
        img: "assets/mete_gazoz.jpg",
        description:
          "2023 Dünya Okçuluk Şampiyonası - Altın Madalya sahibi sporcu",
      },
      {
        title: "Tuğba Danışmaz",
        img: "assets/tugba_danismaz.jpg",
        description:
          "2023 Avrupa Salon Atletizm Şampiyonası - Altın Madalya, 2023 Avrupa Oyunları - Gümüş Madalya sahibi üç adım atlama sporcusu",
      },
      {
        title: "Yasemin Adar",
        img: "assets/yasemin_adar.jpg",
        description:
          "2023 Avrupa Güreş Şampiyonası - Turnuva kariyerinde 6. kez Altın Madalya alan sporcu",
      },
    ],
  },
  {
    title: "Gillette Yılın Futbolcusu",
    options: [
      {
        title: "Ferdi Kadıoğlu",
        img: "assets/ferdi_kadioglu.jpg",
        description:
          "Fenerbahçe ve A Milli Futbol Takımı - 2022 - 2023 sezonunda yer aldığı takımların öne çıkan sporcusu",
      },
      {
        title: "Hakan Çalhanoğlu",
        img: "assets/hakan_calhanoglu.jpg",
        description: "nter - 2023 Şampiyonlar Ligi Finali'nde oynama başarısı",
      },
      {
        title: "Kerem Aktürkoğlu",
        img: "assets/kerem_akturkoglu.jpg",
        description:
          "Galatasaray - 2022 - 2023 Sezonu Spor Toto Süper Lig Asist Kralı",
      },
      {
        title: "Mauro Icardi",
        img: "assets/mauro_icardi.jpg",
        description:
          "2022 - 2023 Sezonu Spor Toto Süper Lig Şampiyonu Galatasaray'ın En Golcü Oyuncusu",
      },
      {
        title: "Selda Akgöz",
        img: "assets/seldaakgoz.jpg",
        description:
          "2022 - 2023 Sezonu Turkcell Kadın Futbol Süper Ligi Şampiyonu ABB Fomget G.S.K'nın Kaptanı",
      },
      {
        title: "Yağmur Uraz",
        img: "assets/yagmur_uraz.jpg",
        description:
          "2022 - 2023 Sezonu Turkcell Kadın Futbol Süper Ligi Gol Kraliçesi",
      },
    ],
  },
];

function App() {
  const firebaseConfig = {
    apiKey: "AIzaSyAk3jApwBQN6d4W62n5-HUr_i6mtj_SUhc",
    authDomain: "gmyso-fc557.firebaseapp.com",
    projectId: "gmyso-fc557",
    storageBucket: "gmyso-fc557.appspot.com",
    messagingSenderId: "116752360553",
    appId: "1:116752360553:web:82d56895a925c91faa2ef8",
    measurementId: "G-0XZRQQ4VE8",
  };

  // Initialize Firebase
  const app = initializeApp(firebaseConfig);
  const analytics = getAnalytics(app);

  const queryParams = new URLSearchParams(window.location.search);
  const user = queryParams.get("user");

  const db = getDatabase();

  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState(new Set());

  const isMobile = useMediaQuery("(max-width:600px)");

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  function trToHtml(trString) {
    // Türkçe karakterleri HTML kodlarıyla değiştir
    var htmlString = trString
      .replace(/ğ/g, "g")
      .replace(/ü/g, "u")
      .replace(/ş/g, "s")
      .replace(/ı/g, "i")
      .replace(/ö/g, "o")
      .replace(/ç/g, "c")
      .replace(/ /g, "_");
    return htmlString;
  }

  const handleNext = (item) => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
    // const userListRef = ref(db, "users/" + user);
    // const newUserRef = push(userListRef);
    // set(newUserRef, item);
    // const ref = db.ref("users/" + user);
    if (item?.title) {
      // const userRef = ref(db, "users/" + user);
      // console.log("serkan", );
      set(ref(db, "users/" + user + "/" + trToHtml(item.title)), item)
        .then(() => {
          // Data saved successfully!
        })
        .catch((error) => {
          // The write failed...
        });
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const shuffleArray = (array) => {
    return array.slice().sort(() => Math.random() - 0.5);
  };

  const [shuffleSteps, setShuffleSteps] = useState(null);

  useEffect(() => {
    setShuffleSteps(shuffleArray(steps));
  }, []);

  const theme = createTheme({
    palette: {
      mode: "dark",
      primary: {
        main: "#004D9D",
      },
      secondary: {
        main: "#4e6cff",
        light: "#7d97ff",
        dark: "#264bcc",
        contrastText: "#ffffff",
      },
    },
  });

  console.log("shuffleSteps.length", shuffleSteps?.length);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Grid container style={{ height: "calc(100vh)", overflow: "hidden" }}>
        {(
          <>
            <Box
              sx={{
                display: "flex",
                width: "100%",
                height: "100%", // İstenilen yükseklik
                backgroundImage: isMobile
                  ? `url("assets/ilkmobilv4.jpg")`
                  : `url("assets/glowsuz1200x640.jpg")`,
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
              }}
            >
              <Typography
                variant={isMobile ? "h5" : "h3"}
                component={isMobile ? "h5" : "h3"}
                sx={{
                  display: "flex",
                  flex: 1,
                  fontWeight: 600,
                  width: "100%",
                  justifyContent: "center",
                  alignItems: isMobile ? "center" : "center",
                  marginTop: isMobile ? 0 : "196px",
                }}
              >
                TEŞEKKÜRLER
              </Typography>
            </Box>
          </>
        )}

        {user && !user && (
          <Box sx={{ width: "100%" }} p={3}>
            {activeStep !== 0 && activeStep < shuffleSteps.length + 1 && (
              <MobileStepper
                variant="progress"
                steps={shuffleSteps ? shuffleSteps.length + 2 : 0}
                position="static"
                style={{
                  height: 50,
                  display: "flex",
                  justifyContent: "center",
                }}
                activeStep={activeStep}
                // nextButton={
                //   <Button
                //     size="small"
                //     onClick={handleNext}
                //     disabled={activeStep === shuffleSteps?.length + 1}
                //   >
                //     Next
                //   </Button>
                // }
                LinearProgressProps={{ style: { flex: 1 } }}
                backButton={
                  <Button
                    size="small"
                    onClick={handleBack}
                    disabled={activeStep === 0}
                  >
                    GERİ
                  </Button>
                }
              />
            )}
            <Box sx={{ display: "flex", flex: 1, height: "100%" }}>
              {activeStep === 0 && (
                <Box
                  onClick={handleNext}
                  sx={{
                    display: "flex",
                    width: "100%",
                    flexDirection: "column",
                    height: "100%", // İstenilen yükseklik
                    backgroundImage: isMobile
                      ? `url("assets/ilkmobilv4.jpg")`
                      : `url("assets/glowsuz1200x640.jpg")`,
                    backgroundSize: "contain",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    justifyContent: "center",
                    alignItems: "flex-end",
                    padding: "108px 56px",
                    textAlign: "center",
                  }}
                >
                  <Typography
                    variant={isMobile ? "h5" : "h3"}
                    component={isMobile ? "h5" : "h3"}
                    sx={{
                      display: "flex",
                      flex: 1,
                      fontWeight: 600,
                      width: "100%",
                      justifyContent: "center",
                      alignItems: "flex-end",
                      marginBottom: "24px",
                    }}
                  >
                    OYLAMAYA BAŞLA
                  </Typography>
                  <Button
                    variant="contained"
                    style={{
                      display: "flex",
                      // width: "100%",
                      alignItems: "center",
                      alignSelf: "center",
                      marginBottom: "12px",
                      backgroundColor: "#00D4F2",
                      color: "#011B72",
                      justifyContent: "center",
                    }}
                  >
                    BAŞLAMAK İÇİN TIKLAYINIZ
                  </Button>
                  <Typography
                    variant="body1"
                    component="p"
                    p={isMobile ? 0 : 5}
                  >
                    Degerli Jürimiz, 70. Gillette Milliyet Yilin Sporcusu
                    Odüllerinde halk oylamasinin ardindan nihai sonuclar
                    sizlerin son karanyla belirleniyor. 3 Mayıs 2024 Cuma günü
                    saat 14:00' ye kadar 7 kategorideki adaylanmiz arasindan
                    size göre alanlarindaki en basanli sporculara oy vererek bu
                    prestiliödülü kazanmalanni saglayacaksiniz!
                  </Typography>
                </Box>
              )}
              {activeStep !== 0 &&
                shuffleSteps &&
                activeStep !== shuffleSteps.length + 1 && (
                  <Question
                    question={shuffleSteps[activeStep - 1]}
                    onClick={handleNext}
                  />
                )}
              {shuffleSteps && activeStep === shuffleSteps.length + 1 && (
                <Box
                  sx={{
                    display: "flex",
                    width: "100%",
                    height: "100%", // İstenilen yükseklik
                    backgroundImage: isMobile
                      ? `url("assets/ilkmobilv4.jpg")`
                      : `url("assets/glowsuz1200x640.jpg")`,
                    backgroundSize: "contain",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                  }}
                >
                  <Typography
                    variant={isMobile ? "h5" : "h3"}
                    component={isMobile ? "h5" : "h3"}
                    sx={{
                      display: "flex",
                      flex: 1,
                      fontWeight: 600,
                      width: "100%",
                      justifyContent: "center",
                      alignItems: isMobile ? "center" : "center",
                      marginTop: isMobile ? 0 : "196px",
                    }}
                  >
                    TEŞEKKÜRLER
                  </Typography>
                </Box>
              )}
            </Box>
          </Box>
        )}
      </Grid>
    </ThemeProvider>
  );
}

export default App;
