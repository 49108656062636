import React, { useState } from "react";
import { Box, useMediaQuery } from "@mui/material";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import ImageListItemBar from "@mui/material/ImageListItemBar";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import InfoIcon from "@mui/icons-material/Info";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const Question = ({ question, onClick }) => {
  const [title, setTitle] = useState(null);
  const [description, setDescription] = useState(null);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const isMobile = useMediaQuery("(max-width:600px)");

  function scrollToTop() {
    const scrollableDiv = document.getElementById("scrollableDiv");
    scrollableDiv.scrollTop = 0; // Yukarı doğru kaydırma
  }
  if (!question) return null;
  return (
    <Box sx={{ width: "100%" }}>
      <Typography id="title" variant="h5" component="h2">
        {question.title}
      </Typography>
      <Box sx={{ overflow: "scroll" }}>
        <ImageList
          id={"scrollableDiv"}
          sx={{ width: "100%", height: "calc(100vh - 148px)" }}
          cols={
            isMobile &&
            (question.title === "AXA Sigorta Yılın Takımı" ||
              question.title === "Yılın Paralimpik - Engelli Sporcusu/Takımı")
              ? 1
              : isMobile
              ? 2
              : question.title === "AXA Sigorta Yılın Takımı" ||
                question.title === "Yılın Paralimpik - Engelli Sporcusu/Takımı"
              ? 3
              : 6
          }
          style={{ gap: "24px" }}
          // rowHeight={220}
        >
          {question.options.map((item) => (
            <Box>
              <ImageListItem key={item.img} variant={"quilted"}>
                <img
                  srcSet={`${item.img}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                  src={`${item.img}?w=164&h=164&fit=crop&auto=format`}
                  alt={item.title}
                  loading="lazy"
                />
                <ImageListItemBar
                  title={item.title}
                  subtitle={item.author}
                  actionIcon={
                    <IconButton
                      sx={{ color: "rgba(255, 255, 255, 0.54)" }}
                      aria-label={`info about ${item.title}`}
                      onClick={() => {
                        setTitle(item.title);
                        setDescription(item?.description);
                        handleOpen();
                      }}
                    >
                      <InfoIcon />
                    </IconButton>
                  }
                />
              </ImageListItem>
              <Button
                variant="contained"
                fullWidth
                sx={{ borderRadius: 0 }}
                onClick={() => {
                  scrollToTop();
                  onClick(item);
                }}
              >
                Oy Ver
              </Button>
            </Box>
          ))}
        </ImageList>
      </Box>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {title}
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            {description}
          </Typography>
        </Box>
      </Modal>
    </Box>
  );
};

export default Question;
